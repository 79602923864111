import React from 'react';
import { Container, Row, Col } from 'reactstrap';

import { ContactForm } from './ContactForm';

import imgSplash from './bmh-splash.jpg';
import imgDoomBunnies from './artwork/red-doom-bunnies.jpg';
import imgFerretSpaceship from './artwork/ferret-spaceship-sheep-girl.jpg';
import imgOwlMoon from './artwork/owl-moon-light.jpg';
import imgBeeHive from './artwork/bee-hive-east-troy-art-class.jpg';
import imgMeltingBunny from './artwork/melting-doom-bunny-balloon.jpg';
import imgDoomBunnyStool from './artwork/doom-bunnies-on-stool.jpg';
import imgDonut from './artwork/donut-realistic.jpg';
import imgBenBroccoli from './artwork/ben-keating-hates-broccoli.jpg';
import imgBridgeOhNo from './artwork/bridge-oh-no.jpg';
import imgGirlFairies from './artwork/girl-dancing-fairies.jpg';
import imgSpookyGirlDoom from './artwork/spooky-girl-doom-bunnies.jpg';
import imgGirlBunnies from './artwork/blue-wood-green-girl-bunnies.jpg';
import imgCactus from './artwork/cactus-horse-buny-on-wood.jpg';
import imgPetsOnWood from './artwork/pets-on-wood.jpg';
import imgPearGuy from './artwork/pear-guy-hiding-behind-apple.jpg';

import imgCanvasTexture from './canvas-texture.jpg';

import { ReactComponent as SvgHiveLogo } from './the-hive-east-troy-logo.svg';


import imgAtHive1 from './artwork/at-the-hive-1.jpg';
import imgAtHive2 from './artwork/at-the-hive-2.jpg';
import imgAtHive3 from './artwork/at-the-hive-3.jpg';

export const App = () => (
  <Container>
    <Row> {/* Gives us two columns for the entire page */}
      <Col xs="12" md="9">

        <Row>
          <Col md="12">
            <img src={imgDoomBunnies} alt="" className="painting" />
          </Col>
        </Row>

        <Row>
          <Col md="4">
            <img src={imgOwlMoon} alt="" className="painting" />
            <img src={imgPetsOnWood} alt="" className="painting" />
            <img src={imgDoomBunnyStool} alt="" className="painting" />
            <img src={imgCactus} alt="" className="painting" />
          </Col>
          <Col md="8">
            <div style={{ width: '100%', margin: 'auto', textAlign: 'justify' }}>
              <img src={imgSplash} alt="" className="painting" />
              <p style={{ marginTop: 20 }}>Bridgette Hopper is a 35-year-old american artist with a full-time career in teaching and illustration. She has always had a passion for painting, and after graduating from the California Academey of Arts with a Bachelor of Traditional Illustration in 2006, she began teaching while comissioning painted works and workshops on the side. With a professionally developed skillset, Bridgette’s painting style is constantly evolving, both in subject matter and stylistic technique, while maintaining a consistent playfulness in all her work.</p>

              <p>Bridgette is currently living in East Troy Wisconsin, expanding her artistic horizons and collecting inspiration to prepare for her next children’s book project.</p>
            </div>
          </Col>
        </Row>

      </Col>
      <Col md="3">
        <Row className="row">
          <Col md="12">
            <img src={imgFerretSpaceship} alt="" className="painting" />
            <img src={imgGirlBunnies} alt="" className="painting" />
            <img src={imgBeeHive} alt="" className="painting" />
            <img src={imgDonut} alt="" className="painting" />
            <img src={imgMeltingBunny} alt="" className="painting" />
            <img src={imgPearGuy} alt="" className="painting" />
          </Col>
        </Row>
      </Col>

    </Row>

    <div style={{ margin: 'auto', background: '#5a5a5a', marginBottom: 30, padding: 20, borderRadius: 5 }}>
      <Row>
        <Col md="4">
          <img src={imgAtHive1} alt="" className="painting" />
        </Col>
        <Col md="4">
          <img src={imgAtHive2} alt="" className="painting" />
        </Col>
        <Col md="4">
          <img src={imgAtHive3} alt="" className="painting" />
        </Col>
      </Row>
      <Row>
        <Col md={{ size: 3}}>
          <SvgHiveLogo style={{ maxWidth: 250 }} />
        </Col>
        <Col md="9">
          <p>Starting in 2019, Bridgette has hosted several social paint classes most of which have taken place at The Hive Taproom in East Troy, Wisconsin. Working with The Hive and their immersive space, Bridgette has come up with a themed painting and educational steps necessary to help lead the class to a fun and beautifully custom painting. You can find Bridgette and her classes posted in most East Troy area news outlets.</p>
        </Col>
      </Row>
    </div>


    <Row>
      <Col
        md="6"
        style={{ marginBottom: 50 }}
        className="contact-form-wrapper"
      >
        <div style={{ backgroundColor: 'white', padding: 20, backgroundImage: `url(${imgCanvasTexture})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat'  }} className="painting">
          <h3>Contact Bridgette</h3>
          <ContactForm />
        </div>
      </Col>
      <Col md="6">

        <Row>
          <Col>
            <img src={imgBenBroccoli} alt="" className="painting" />
          </Col>
          <Col>
            <img src={imgBridgeOhNo} alt="" className="painting" />
          </Col>
        </Row>

        <Row>
          <Col>
            <img src={imgGirlFairies} alt="" className="painting" />
          </Col>
          <Col>
            <img src={imgSpookyGirlDoom} alt="" className="painting" />
          </Col>
        </Row>

      </Col>
    </Row>

  </Container>
)
