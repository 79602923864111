import React, { useState } from 'react';
import { useFormState } from 'react-use-form-state';
import { Alert, Button, Form, FormGroup, Label, Input } from 'reactstrap';

export const ContactForm = () => {
  const [formState, { text, email, textarea }] = useFormState();
  const [isSent, setIsSent] = useState(false);
  const [errMsg, setErrMsg] = useState('');

  function handleSubmit(e) {
      e.preventDefault();
      const { name, email, content } = formState.values;
      fetch(process.env.REACT_APP_CONTACT_FORM_ENDPOINT, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ name, email, content })
      }).then(res => res.json())
      .then(response => setIsSent(true))
      .catch(error => setErrMsg(error));
  }

  const resetForm = () => {
    setIsSent(false);
    setErrMsg('');
  }

  return (
    <div className="subscribe-form">
      <Form onSubmit={handleSubmit}>

        <Alert color="success" isOpen={isSent}>
          Your message has been received. Thank you.
          <Button onClick={resetForm} color="link">Close</Button>
        </Alert>

        <Alert color="danger" isOpen={(errMsg !== '')}>
          Error: {errMsg}
          <Button onClick={resetForm} color="link">Close</Button>
        </Alert>

        <FormGroup>
          <Label>Your Name</Label>
          <Input {...text('name')} />
        </FormGroup>

        <FormGroup>
          <Label>Email Address</Label>
          <Input {...email('email')} />
        </FormGroup>

        <FormGroup>
          <Label>Your Message</Label>
          <textarea {...textarea('content')} className="form-control" style={{ minHeight: 343 }}/>
        </FormGroup>

        <Button type="submit" color="primary" block style={{ marginTop: 20 }}>
          Send Message
        </Button>

      </Form>
    </div>
  );
}