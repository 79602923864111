import React from 'react';
import ReactDOM from 'react-dom';
// import { hydrate, render } from 'react-dom';
import * as Sentry from '@sentry/browser';

import 'bootstrap/dist/css/bootstrap.min.css';

import { App } from './App';
import './style.css';

Sentry.init({ dsn: process.env.REACT_APP_SENTRY_DSN });

ReactDOM.render(<App />, document.getElementById('root'));

// const rootElement = document.getElementById("root");
// if (rootElement.hasChildNodes()) {
//   hydrate(<App />, rootElement);
// } else {
//   render(<App />, rootElement);
// }
